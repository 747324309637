<script lang="ts">
import { defineComponent } from 'vue'
import type { SnackebarOptions } from '@/store/snackbar'
import snackebarStore from '@/store/snackbar'

export default defineComponent({
  data() {
    return {
      snakebarOptions: {} as SnackebarOptions,
      show: false,
      snackebarStore: snackebarStore(),
    }
  },

  created() {
    /** 監聽snackebarStore actions呼叫 */
    this.snackebarStore.$onAction(({ name, store }) => {
      console.log('onAction', name, store)
      this.snakebarOptions = store.snackebarOptions
      this.show = true
    })
  },
})
</script>

<template>
  <VSnackbar
    v-model="show"
    v-bind="snakebarOptions"
  >
    {{ snakebarOptions.content }}
  </VSnackbar>
</template>
